<template>
  <h1>Afrekening</h1>

  <div class="py-4 inline-flex flex-col">
    <h2 class="bg-dashboard-dark text-white mb-0 px-2 py-1">Filter</h2>
    <div class="flex flex-row gap-4 px-2 mt-2">
      <UISelectChauffeur use-query class="w-full max-w-sm" active label v-model="apiFilters.user_id" />
    </div>
  </div>

  <div class="clear-both"></div>

  <div v-if="apiFilters.user_id > 0">
    <h2 class="bg-dashboard-dark text-white px-2 py-1 mb-2 w-2/3">Afrekening</h2>

    <UITableResponsive
      v-model:selected="selectedKasboek"
      select
      select-all
      :items="kasboek.list"
      :headers="['Rit', 'Betaald_datum', 'Bedrag', 'Methode', 'Klant', 'Afgerekend']"
    >
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-16" />
          <col class="w-32" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-32" />
          <col class="w-0" />
        </colgroup>
      </template>
      <template v-slot:item-Rit="{ item }">
        <router-link class="btn info" :to="`/ritten/info/${item.betaling.rit_id}`">
          <i class="fas fa-info-circle"></i> {{ item.betaling.rit_id }}
        </router-link>
      </template>
      <template v-slot:item-Betaald_datum="{ item }">
        <span>{{ dateStringLocale(item.betaling.date) }}</span>
      </template>
      <template v-slot:item-Bedrag="{ item }">
        <span>&euro;&nbsp;{{ formatPrice(item.betaling.bedrag) }}</span>
      </template>
      <template v-slot:item-Methode="{ item }">
        <span>{{ uppercase(item.betaling.methode) }}</span>
      </template>
      <template v-slot:item-Klant="{ item }">
        <span>{{ [item.klant.firstname, item.klant.lastname].join(' ').trim() }}</span>
      </template>
      <template v-slot:item-Afgerekend="{ item }">
        <UILabelJaNeen :value="item.betaling.is_afgerekend" />
      </template>
    </UITableResponsive>
  </div>
  <div v-if="selectedKasboek.length" class="mt-2">
    <div class="inline-flex flex-col justify-start items-start gap-2 border-dashboard-dark border p-2 -mt-1">
      <h2 class="bg-dashboard-dark text-white px-2 py-1 -m-2 mb-2">
        Afrekenen met chauffeur ({{ selectedKasboek.length }} betalingen)
      </h2>
      <LabelValue
        v-for="method in ['CASH', 'BETAALTERMINAL', 'OVERSCHRIJVING']"
        :key="method"
        :label="method"
        class="block"
      >
        &euro;&nbsp;{{ formatPrice(selectedTotalByMethod(method)) }}
      </LabelValue>

      <UIInputLabel class="font-bold">Tickets</UIInputLabel>
      <div class="flex flex-row gap-2 -mt-2">
        <UIInput type="number" label="Reeds afgehouden" v-model="tickets.reeds_betaald" />
        <UIInput type="number" label="Terug te betalen" v-model="tickets.terug_te_betalen" />
      </div>

      <LabelValue label="Af te rekenen CASH" class="mx-auto">
        <span class="text-white px-2 py-1 mt-4 rounded inline-block bg-blue-800">
          &euro;&nbsp;<b>{{ formatPrice(selectedTotalByMethod('CASH') - Number(tickets.reeds_betaald) - Number(tickets.terug_te_betalen)) }}</b>
        </span>
      </LabelValue>

      <button class="btn success mx-auto" @click="afrekenen">Nu afrekenen</button>
    </div>
  </div>

  <div v-if="apiFilters.user_id > 0" class="mt-8">
    <h2 class="bg-dashboard-dark text-white px-2 py-1 mb-2 w-2/3">Gereden maar onbetaald</h2>
    <div class="flex gap-4 mb-2">
      <UIInputCheckbox label="Enkel te ontvangen tonen" v-model="filters.onbetaald_te_ontvangen" />
      <UIInputCheckbox label="Enkel zonder factuur tonen" v-model="filters.ontbetaald_zonder_factuur" />
    </div>

    <UITableResponsive
      :items="onbetaaldGefilterd"
      :headers="[
        'Status',
        'Gereden_datum',
        'Rit_deel',
        'Rit_prijs',
        'Missend_bedrag',
        'Te_ontvangen',
        'Opmerkingen',
        'Klant',
        'Acties',
      ]"
    >
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-32" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-48" />
        </colgroup>
      </template>
      <template v-slot:item-Status="{ item }">
        <div class="flex gap-4">
          <span class="px-2 bg-green-500 rounded-sm text-white" v-if="item.rit.bedrag_open_saldo <= 0">
            Volledig betaald
          </span>
          <span class="px-2 bg-yellow-400 rounded-sm text-white" v-else-if="item.rit.bedrag_ontvangen > 0">Deels betaald</span>
          <span class="px-2 bg-red-500 rounded-sm text-white" v-else>Onbetaald</span>
          <span
            class="px-2 bg-green-500 rounded-sm text-white"
            v-if="item.rit.facturatie_id || item.rit.is_factuur_nodig"
          >
            Op factuur
          </span>
          <span class="px-2 bg-red-500 rounded-sm text-white" v-else>Niet op factuur</span>
        </div>
      </template>
      <template v-slot:item-Gereden_datum="{ item }">
        {{ dateStringLocale(item.planning?.is_retour ? item.rit.retour_datetime : item.rit.vertrek_datetime) }}
      </template>
      <template v-slot:item-Rit_deel="{ item }">
        <small>
          <TextRitDeel :is-retour="item.rit.is_retour" :deel-retour="item.planning.is_retour" />
          {{ item.rit.is_retour ? 'gereden' : '' }}
        </small>
      </template>
      <template v-slot:item-Rit_prijs="{ item }">
        <span>&euro;&nbsp;{{ formatPrice(item.rit.prijs) }}</span>
      </template>
      <template v-slot:item-Missend_bedrag="{ item }">
        <span class="text-red-700 font-semibold">
          &euro;&nbsp;{{ formatPrice(item.rit.prijs - item.rit.bedrag_ontvangen) }}
        </span>
      </template>
      <template v-slot:item-Te_ontvangen="{ item }">
        <UILabelJaNeen :value="item.rit.is_te_ontvangen" />
      </template>
      <template v-slot:item-Opmerkingen="{ item }">
        <div class="flex flex-col gap-1 -my-1">
          <ButtonAlertOpmerking :opmerking="item.rit?.opmerking" />
          <ButtonAlertPlanningOptie
            v-if="item.rit"
            v-model:heen-memo="item.rit.planning_optie_heen"
            v-model:retour-memo="item.rit.planning_optie_retour"
            :is-retour="item.planning?.is_retour"
            :hide-heen="item.planning?.is_retour"
            :hide-retour="!item.planning?.is_retour"
            :preview-length="0"
            disabled
          />
        </div>
      </template>
      <template v-slot:item-Klant="{ item }">
        <span>{{ [item.klant.firstname, item.klant.lastname].join(' ').trim() }}</span>
      </template>
      <template v-slot:item-Acties="{ item }">
        <div class="flex gap-2">
          <UIButtonRitBetaling :rit="item.rit" :user_id="apiFilters.user_id">Betaling</UIButtonRitBetaling>
          <router-link class="btn info" :to="`/ritten/info/${item.rit.id}`">
            <i class="fas fa-info-circle"></i> {{ item.rit.id }}
          </router-link>
          <router-link
            class="btn info"
            :to="`/ritten/kalender?date=${dateString(
              item.planning.is_retour ? item.rit.retour_datetime : item.rit.vertrek_datetime,
            )}`"
          >
            <i class="fas fa-calendar"></i> Kalender
          </router-link>
          <router-link class="btn warning" :to="`/ritten/bewerken/${item.rit.id}`">
            <i class="fas fa-edit"></i> Rit bewerken
          </router-link>
        </div>
      </template>
      <template v-slot:item-Afgerekend="{ item }">
        <UILabelJaNeen :value="item.betaling.is_afgerekend" />
      </template>
    </UITableResponsive>
  </div>

  <!-- @TODO: layout overnemen/vergelijken met oud dashboard -->
  <div v-if="apiFilters.user_id > 0" class="mt-8">
    <h2 class="bg-dashboard-dark text-white px-2 py-1 mb-2 w-2/3">Cash afgerekend maar niet door toegewezen chauffeur(s) (Verkeerd?)</h2>

    <UITableResponsive
      :items="verkeerdGefilterd"
      :headers="[
        'Cash_bedrag',
        'Ontvangen_door',
        'Is_afgerekend',
        'Gereden_door',
        'Gereden_datum',
        'Rit_deel',
        'Rit_prijs',
        'Missend_bedrag',
        'Te_ontvangen',
        'Acties',
      ]"
    >
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-32" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-48" />
        </colgroup>
      </template>
      <template v-slot:item-Cash_bedrag="{ item }">
        {{ formatPrice(item.betaling.bedrag) }}
      </template>
      <template v-slot:item-Ontvangen_door="{ item }">
        <span v-if="item.betaling?.user_id > 0">
          {{ store.getters.chauffeur(item.betaling.user_id)?.label }}
        </span>
      </template>
      <template v-slot:item-Is_afgerekend="{ item }">
        <UILabelJaNeen :value="item.betaling.is_afgerekend" />
      </template>
      <template v-slot:item-Gereden_door="{ item }">
        <span v-if="item.planning?.user_id > 0">
          {{ store.getters.chauffeur(item.planning.user_id)?.label }}
        </span>
      </template>
      <template v-slot:item-Gereden_datum="{ item }">
        <span v-if="item.planning">
          {{ dateStringLocale(item.planning?.is_retour ? item.rit.retour_datetime : item.rit.vertrek_datetime) }}
        </span>
      </template>
      <template v-slot:item-Rit_deel="{ item }">
        <TextRitDeel :is-retour="item.rit.is_retour" :deel-retour="item.planning.is_retour" />
        {{ item.rit.is_retour ? 'gereden' : '' }}
      </template>
      <template v-slot:item-Rit_prijs="{ item }">
        <span>&euro;&nbsp;{{ formatPrice(item.rit.prijs) }}</span>
      </template>
      <template v-slot:item-Missend_bedrag="{ item }">
        <span class="text-red-700 font-semibold">
          &euro;&nbsp;{{ formatPrice(item.rit.prijs - item.rit.bedrag_ontvangen) }}
        </span>
      </template>
      <template v-slot:item-Te_ontvangen="{ item }">
        <UILabelJaNeen :value="item.rit.is_te_ontvangen" />
      </template>
      <template v-slot:item-Klant="{ item }">
        <span>{{ [item.klant.firstname, item.klant.lastname].join(' ').trim() }}</span>
      </template>
      <template v-slot:item-Acties="{ item }">
        <div class="flex gap-2">
          <router-link class="btn info" :to="`/ritten/info/${item.rit.id}?deel=${item.planning.is_retour ? 'retour' : 'heen'}`">
            <i class="fas fa-info-circle"></i> {{ item.rit.id }}
          </router-link>
          <router-link
            class="btn info"
            :to="`/ritten/kalender?date=${dateString(
              item.planning.is_retour ? item.rit.retour_datetime : item.rit.vertrek_datetime,
            )}`"
          >
            <i class="fas fa-calendar"></i> Kalender
          </router-link>
        </div>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import { watch, ref, inject, computed, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

import UITableResponsive from '@/components/UI/Table/Responsive'
import UILabelJaNeen from '@/components/UI/Labels/JaNeen.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import { dateStringLocale, dateString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import { uppercase } from '@/functions/formatText'
import LabelValue from '@/components/UI/Labels/LabelValue.vue'
import TextRitDeel from '@/components/UI/Text/RitDeel.vue'
import UIInputLabel from '@/components/UI/Input/Label.vue'
import UIInput from '@/components/UI/Input/Input'
import UIInputCheckbox from '@/components/UI/Input/Checkbox'
import UIButtonRitBetaling from '@/components/UI/Button/RitBetaling.vue'
const ButtonAlertPlanningOptie = defineAsyncComponent(() => import('@/components/UI/Button/PlanningOptie.vue'))
const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))

const axios = inject('axios')
const store = useStore()

const emptyList = () => ({ list: [], count: 0 })
const kasboek = ref(emptyList())
const onbetaald = ref(emptyList())
const verkeerd = ref(emptyList())

const selectedKasboek = ref([])
const selectedTotalByMethod = computed(() => method => {
  const arr = selectedKasboek.value
  return arr.reduce((total, next) => total + (next?.betaling?.methode === method ? next.betaling.bedrag : 0), 0)
})

const tickets = ref({
  reeds_betaald: 0,
  terug_te_betalen: 0,
})
const filters = ref({
  onbetaald_te_ontvangen: false,
  ontbetaald_zonder_factuur: false,
})
const onbetaaldGefilterd = computed(() =>
  onbetaald.value.list.filter(
    el =>
      el &&
      el.rit.prijs > 0 &&
      (!filters.value.onbetaald_te_ontvangen || el.rit.is_te_ontvangen) &&
      (!filters.value.ontbetaald_zonder_factuur || (!el.rit.is_factuur_nodig && !el.rit.facturatie_id)),
  ),
)
const verkeerdGefilterd = computed(() => verkeerd.value.list.filter(el => el && el.rit.prijs > 0))

const apiFilters = ref({
  user_id: null,
})

watch(apiFilters.value, () => resetAndGetData())

const resetAndGetData = () => {
  kasboek.value = emptyList()
  onbetaald.value = emptyList()
  verkeerd.value = emptyList()
  selectedKasboek.value = []
  if (apiFilters.value.user_id > 0) {
    getData(null, true)
  }
}

const getData = async (_params = null, setVm = true) => {
  const params = _params || apiFilters.value
  const [{ data: kasboekData }, { data: onbetaaldData }, { data: verkeerdData }] = await Promise.all([
    axios.get('/api/dashboard/afrekenen/list', { params }),
    axios.get('/api/dashboard/afrekenen/onbetaald', { params }),
    axios.get('/api/dashboard/afrekenen/verkeerd', { params }),
  ]).catch(err => console.error(err))
  if (setVm) {
    kasboek.value.list = kasboekData.list || []
    kasboek.value.count = kasboekData.count || 0
    onbetaald.value.list = onbetaaldData.list || []
    onbetaald.value.count = onbetaaldData.count || 0
    verkeerd.value.list = verkeerdData.list || []
    verkeerd.value.count = verkeerdData.count || 0
  }
  return {
    kasboek: kasboek.value,
    onbetaald: onbetaald.value,
    verkeerd: verkeerd.value,
  }
}

resetAndGetData()

const afrekenen = () => {
  axios
    .patch('/api/dashboard/afrekenen/afrekenen', { ids: selectedKasboek.value.map(el => el.betaling.id) })
    .then(() => {
      resetAndGetData()
    })
}
</script>

<style lang="scss" scoped>
ul {
  border-collapse: collapse;
  @apply border border-dashboard-dark;
}
ul li {
  @apply border border-dashboard-dark;
  @apply px-2 py-1 pr-4;
  border-collapse: collapse;
}
</style>
